import React, { Component } from "react"
import moment from "moment"
import ImagesService from "../../utils/images-service"

import "./styles.scss"
import "moment/min/locales"

class Client extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        moment.locale('de')

        let contentClasses = ""

        if (this.blok.stations.length > 0) {
            contentClasses += "hasStations "
        } else {
            contentClasses += "hasNoStations "
        }

        if (this.blok.succeses.length > 0) {
            contentClasses += "hasSuccesses "
        } else {
            contentClasses += "hasNoSuccesses "
        }

        if (this.blok.awards.length > 0) {
            contentClasses += "hasAwards "
        } else {
            contentClasses += "hasNoAwards "
        }

        if (this.blok.publications.length > 0 || this.blok.foundations.length > 0) {
            contentClasses += "hasMixedContent"
        } else {
            contentClasses += "hasNoMixedContent"
        }

        contentClasses.trim();

        return (
            <div className="client-profile">
                <div className="profile-header">
                    <div className="inside">
                        <picture>
                            <source media="(max-width: 740px)" srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '740x555/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '1480x1100/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source media="(max-width: 740px)" srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '740x555/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '1480x1100/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <source srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '2720x1200/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '2720x1200/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <img loading="lazy" src={ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(jpeg):quality(60)'})} alt={this.blok.page_picture.alt} width="1360" height="600" />
                        </picture>
                        <div className="nametag">
                            <div className="name">{this.blok.firstname} {this.blok.lastname}</div><br/>
                            <div className="position">{this.blok.profession}</div>
                            <div className="birthday">* {moment(this.blok.birthday).format('Do MMMM YYYY')} in {this.blok.birthplace}</div>
                            <div className="contact-options">
                                {this.blok.website.url !== "" &&
                                <div className="button-wrapper">
                                    <a href={this.blok.website.url} className="button primary" target="_blank" rel="noreferrer">Webseite <span className="hide-on-mobile">öffnen</span></a>
                                </div>
                                }
                                {this.blok.linkedin_profile.url !== "" &&
                                <a href={this.blok.linkedin_profile.url} className="linkedin-link" title="LinkedIn-Profil öffnen" target="_blank" rel="noreferrer">LinkedIn</a>
                                }
                                {this.blok.twitter_profile.url !== "" &&
                                <a href={this.blok.twitter_profile.url} className="twitter-link" title="Twitter-Profil öffnen" target="_blank" rel="noreferrer">Twitter</a>
                                }
                                {this.blok.facebook_profile.url !== "" &&
                                <a href={this.blok.facebook_profile.url} className="facebook-link" title="Facebook-Profil öffnen" target="_blank" rel="noreferrer">Facebook</a>
                                }
                                {this.blok.instagram_profile.url !== "" &&
                                <a href={this.blok.instagram_profile.url} className="instagram-link" title="Instagram-Profil öffnen" target="_blank" rel="noreferrer">Instagram</a>
                                }
                                {this.blok.xing_profile.url !== "" &&
                                <a href={this.blok.xing_profile.url} className="xing-link" title="XING-Profil öffnen" target="_blank" rel="noreferrer">XING</a>
                                }
                                {this.blok.transfermarkt_profile.url !== "" &&
                                <a href={this.blok.transfermarkt_profile.url} className="transfermarkt-link" title="transfermarkt.de-Profil öffnen" target="_blank" rel="noreferrer">transfermarkt.de</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className={`profile-details ${contentClasses}`}>
                        {this.blok.stations.length > 0 &&
                        <div className="stations">
                            <h3>Stationen</h3>
                            <ol>
                            {this.blok.stations.map((station, index) => {
                                return (<li key={index}>
                                    {station.current &&
                                        <div className="station active">
                                            <div className="timeline">
                                                <div className="point" />
                                                <div className="line" />
                                            </div>
                                            <div className="label">
                                                <div className="position">{station.position}, {station.employer}</div>
                                                <div className="date">
                                                {station.end_date !== "" &&
                                                    <div>{`${moment(station.start_date).format("YYYY")}`} - {`${moment(station.end_date).format("YYYY")}`}</div>
                                                }
                                                {station.end_date === "" &&
                                                    <div>{`seit ${moment(station.start_date).format("MMMM YYYY")}`}</div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!station.current &&
                                        <div className="station">
                                            <div className="timeline">
                                                <div className="point" />
                                                <div className="line" />
                                            </div>
                                            <div className="label">
                                                <div className="position">{station.position}, {station.employer}</div>
                                                <div className="date">
                                                {station.end_date !== "" &&
                                                    <div>{`${moment(station.start_date).format("YYYY")}`} - {`${moment(station.end_date).format("YYYY")}`}</div>
                                                }
                                                {station.end_date === "" &&
                                                    <div>{`seit ${moment(station.start_date).format("MMMM YYYY")}`}</div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>)
                            })}
                            </ol>
                        </div>
                        }
                        {this.blok.succeses.length > 0 &&
                        <div className="successes">
                            <h3>Erfolge</h3>
                            <ol>
                                {this.blok.succeses.map((success, index) => {
                                    return (<li className="success" key={index}>
                                        <div className="name">{success.name}</div>
                                        <div className="years">
                                            {success.years.map((year, index) => {
                                                return (<div key={index}>
                                                    {year.employer === "" &&
                                                        <span>{year.year}</span>
                                                    }
                                                    {year.employer !== "" &&
                                                        <span>{year.year} (mit {year.employer})</span>
                                                    }
                                                    {success.years.length !== index+1 &&
                                                        <span>, </span>
                                                    }
                                                </div>)
                                            })}
                                        </div>
                                    </li>)
                                })}
                            </ol>
                        </div>
                        }
                        {(this.blok.publications.length > 0 || this.blok.foundations.length > 0) &&
                        <div className="mixed-content">
                            {this.blok.publications.length > 0 &&
                                <div className="publications">
                                    <h3>Publikationen</h3>
                                    <ul>
                                    {this.blok.publications.map((publication, index) => {
                                        return (<li className="publication" key={index}>
                                            <div className="coverImage">
                                                <picture>
                                                    <source media="(max-width: 450px)" srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '450x0/smart/filters:format(webp)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '900x0/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source media="(max-width: 450px)" srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '450x0/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '900x0/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <source srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(webp)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '250x0/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '250x0/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <img loading="lazy" src={ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(jpeg):quality(60)'})} alt={publication.title} width="125"/>
                                                </picture>
                                            </div>
                                            <div className="information">
                                                <h4>{publication.title}</h4>
                                                <div className="publisher-year">{publication.publication_year}, {publication.publisher}</div>
                                                <div className="description">{publication.teaser}</div>
                                                <div className="button-wrapper">
                                                    <a className="button primary" href={publication.buy_link.url} target="_blank" rel="noreferrer">Mehr erfahren</a>
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                    </ul>
                                </div>
                            }
                            {this.blok.foundations.length > 0 &&
                                <div className="foundations">
                                    <h3>Stiftungen</h3>
                                    <ul>
                                    {this.blok.foundations.map((foundation, index) => {
                                        return (<li className="foundation" key={index}>
                                            <div className="logo">
                                                <picture>
                                                    <source srcSet={`${ ImagesService(foundation.logo.filename, true, { path: '204x114/smart/filters:format(webp)'}) } 1x, ${ ImagesService(foundation.logo.filename, true, { path: '408x228/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source srcSet={`${ ImagesService(foundation.logo.filename, true, { path: '204x114/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(foundation.logo.filename, true, { path: '408x228/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <img loading="lazy" src={ImagesService(foundation.logo.filename, true, { path: '204x114/smart/filters:format(jpeg):quality(60)'})} alt={foundation.name} width="204" height="114" />
                                                </picture>
                                            </div>
                                            <div className="information">
                                                <h4>{foundation.name}</h4>
                                                <div className="button-wrapper">
                                                    <a className="button primary" href={foundation.website_link.url} target="_blank" rel="noreferrer">Webseite öffnen</a>
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                    </ul>
                                </div>
                            }
                        </div>
                        }
                        <div className="awards">
                            {this.blok.awards.length > 0 &&
                            <div>
                                <h3>Auszeichnungen</h3>
                                <ol>
                                    {this.blok.awards.map((award, index) => {
                                        return (<li className="award" key={index}>
                                            <div className="name">{award.name}</div>
                                            <div className="years">
                                                {award.years.map((year, index) => {
                                                    return (<div key={index}>
                                                        {year.employer === "" &&
                                                            <span>{year.year}</span>
                                                        }
                                                        {year.employer !== "" &&
                                                            <span>{year.year} (mit {year.employer})</span>
                                                        }
                                                        {award.years.length !== index+1 &&
                                                            <span>, </span>
                                                        }
                                                    </div>)
                                                })}
                                            </div>
                                        </li>)
                                    })}
                                </ol>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
          )
    }
}

export default Client